@import url('https://fonts.googleapis.com/css2?family=Gothic+A1:wght@100;200;300;400;500;600;700;800;900&display=swap');

body,
html {
  font-family: 'Gothic A1', "Open Sans", sans-serif;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  color: #000;
  font-weight: 400;
  width: 100% !important;
  height: 100% !important;
  background: #FAFAFA;
}
h2,
h3,
h4 {
  font-family: 'Gothic A1', "Raleway", sans-serif;
}
h1 {
  margin: 0 0 20px;
  font-size: 40px;
  color: #000;
}
h2 {
  margin: 0 0 20px;
  font-weight: 800;
  font-size: 36px;
  color: #000;
}
h3 {
  font-size: 22px;
  font-weight: 600;
  color: #000;
}
h4 {
  font-size: 18px;
  color: #000;
  font-weight: 600;
}
h5 {
  text-transform: uppercase;
  font-weight: 700;
  line-height: 20px;
}
p {
  font-size: 18px;
}
p.intro {
  margin: 12px 0 0;
  line-height: 24px;
}
a {
  color: #608dfd;
  font-weight: 400;
}
a:hover,
a:focus {
  text-decoration: none;
  color: #608dfd;
}
ul,
ol {
  list-style: none;
}
ul,
ol {
  padding: 0;
}
hr {
  height: 1px;
  text-align: center;
  position: relative;
  background: rgb(128 123 123 / 50%);
  margin-bottom: 20px;
  border: 0;
}
/* Navigation */
#menu {
  padding: 15px;
  transition: all 0.8s;
}
#menu.navbar-default {
  background-color: #F2EAEA;
  border-color: rgba(231, 231, 231, 0);
}
#menu a.navbar-brand {
  font-size: 32px;
  font-weight: 700;
  color: #000;
}
#menu.navbar-default .navbar-nav > li > a {
  color: #000;
  font-size: 18px;
  font-weight: 400;
  padding: 8px 2px;
  border-radius: 0;
  margin: 9px 20px 0;
}
#menu.navbar-default .navbar-nav > li > a.active {
  font-weight: 700;
}
#menu.navbar-default .navbar-nav > li > a:after {
  display: block;
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 0;
  height: 2px;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
  content: "";
  transition: width 0.2s;
}
#menu.navbar-default .navbar-nav > li > a:hover:after {
  width: 100%;
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  background-color: transparent;
  font-weight: 700;
}
.navbar-default .navbar-nav > .active > a:after,
.navbar-default .navbar-nav > .active > a:hover:after,
.navbar-default .navbar-nav > .active > a:focus:after {
  display: block !important;
  position: absolute !important;
  left: 0 !important;
  bottom: -1px !important;
  width: 100% !important;
  height: 2px !important;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%) !important;
  content: "" !important;
  transition: width 0.2s !important;
}
.navbar-toggle {
  border-radius: 0;
}
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background-color: #fff;
  border-color: #608dfd;
}
.navbar-default .navbar-toggle:hover > .icon-bar {
  background-color: #608dfd;
}
.section-title {
  margin-bottom: 50px;
}
.section-title h2 {
  position: relative;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
.section-title h2::after {
  position: absolute;
  content: "";
  background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
  height: 4px;
  width: 60px;
  bottom: 0;
  margin-left: -30px;
  left: 50%;
}
.section-title p {
  font-size: 18px;
}
.btn-custom {
  text-transform: uppercase;
  color: #fff;
  background-color: #5ca9fb;
  background-image: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
  padding: 14px 34px;
  letter-spacing: 1px;
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  border-radius: 25px;
  transition: all 0.5s linear;
  border: 0;
}
.btn-custom:hover,
.btn-custom:focus,
.btn-custom.focus,
.btn-custom:active,
.btn-custom.active {
  color: #fff;
  background-image: none;
  background-color: #6372ff;
}
.btn:active,
.btn.active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
a:focus,
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none;
  outline-offset: none;
}

/* Features Section */
#features {
  padding: 30px 0;
}
#features .section-title h2::after {
  position: absolute;
  content: "";
  background: rgb(128 123 123 / 50%);
  height: 1px;
  width: 80%;
  left: 10%;
  bottom: 0;
  margin: auto;
}
#features .work-step {
  font-size: 38px;
  margin: 20px auto;
  transition: all 0.5s;
  color: #fff;
  width: 70px;
  height: 70px;
  padding: 7px 0;
  border-radius: 50%;
  background: rgba(35, 194, 21, 0.63);
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.05);
}
#features .step-row .step-row-img .img-2 {
  margin: auto;
}

@media (min-width: 768px) {
  #features .step-row {
    display: flex;
    flex-wrap: wrap;
  }
  #features .step-row .col-xs-12 {
    flex-grow: 1;
  }
  #features .step-row-inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    height: 100%;
  }
}

/* About Section */
#about {
  padding: 30px 0;
}
#about h3 {
  font-size: 22px;
  margin: 0 0 20px;
}
#about h2 {
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 15px;
  font-size: 48px;
}

#about .about-text li {
  margin-bottom: 6px;
  margin-left: 6px;
  list-style: none;
  padding: 0;
}
#about .about-text li:before {
  content: "\f00c";
  font-family: "FontAwesome";
  color: #5ca9fb;
  font-size: 11px;
  font-weight: 300;
  padding-right: 8px;
}
#about img {
  width: 500px;
  margin-bottom: 20px;
  background: #fff;
  border-right: 0;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.06);
}
@media (max-width: 768px) {
  #about img {
    width: 100%;
  }
}
#about p {
  line-height: 24px;
  margin: 30px auto;
  max-width: 400px;
  font-size: 20px;
}
#about .img-2 {
  width: 100%;
}
#about .download-label {
  background: rgba(29, 161, 17, 0.79);
  width: 100%;
  max-width: 400px;
  margin: auto;
  padding: 20px;
  color: #fff;
  font-size: 28px;
  border-radius: 50px;
  margin-top: 20px;
}
#about .download-links {
  width: 100%;
  max-width: 410px;
  margin: 50px auto 20px;
}
#about .download-links div {
  padding: 5px;
}
/* Services Section */
#services {
  padding: 100px 0;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
  color: #fff;
}
#services .service-desc {
  margin: 10px 10px 20px;
}
#services h2 {
  color: #fff;
}
#services .section-title h2::after {
  position: absolute;
  content: "";
  background: rgba(255, 255, 255, 0.3);
  height: 4px;
  width: 60px;
  bottom: 0;
  margin-left: -30px;
  left: 50%;
}
#services i.fa {
  font-size: 42px;
  width: 120px;
  height: 120px;
  padding: 40px 0;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
  border-radius: 50%;
  color: #fff;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.05);
}
#services h3 {
  font-weight: 500;
  padding: 5px 0;
  color: #fff;
}
#services p {
  color: rgba(255, 255, 255, 0.75);
}
#services .service-desc {
  margin-bottom: 40px;
}

/* Testimonials Section */
#testimonials {
  padding: 60px 0;
}
#testimonials .section-title h2 {
  padding: 0;
}
#testimonials .section-title h2::after {
  display: none;
}
#testimonials .section-title p {
  max-width: 500px;
  margin: auto;
}
#testimonials i {
  color: #e6e6e6;
  font-size: 32px;
  margin-bottom: 20px;
}
.testimonial {
  position: relative;
  padding: 20px;
}
.testimonial-content {
  position: relative;
  overflow: hidden;
}
.contact-link-wrapper {
  position: relative;
  padding: 20px;
}
.contact-link {
  display: flex;
  align-items: center;
  padding: 15px 40px;
  background: #fff;
  border-radius: 25px;
}
.contact-link-left {
  flex-grow: 1;
}
.contact-link p {
  color: #000;
}

/* Contact Section */
#contact {
  min-height: calc( 100vh - 200px );
}
#contact .section-title {
  margin-bottom: 20px;
}
#contact .section-title p {
  font-size: 16px;
}
#contact h2 {
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
#contact .section-title h2::after {
  position: absolute;
  content: "";
  background: rgba(255, 255, 255, 0.3);
  height: 4px;
  width: 60px;
  bottom: 0;
  left: 30px;
}
#contact h3 {
  color: #fff;
  margin-top: 80px;
  margin-bottom: 25px;
  padding-bottom: 20px;
  font-weight: 400;
}
#contact form {
  padding-top: 20px;
}
#contact .text-danger {
  color: #cc0033;
  text-align: left;
}
#contact .btn-custom {
  margin: 30px 0;
  background: rgba(29,161,17,0.79);
  border: 2px solid #fff;
  min-width: 200px;
  font-size: 20px;
  text-transform: none;
}
#contact .btn-custom:hover {
  color: #fff;
  background: #0000EE;
}
label {
  font-size: 12px;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  float: left;
}
#contact .form-control {
  display: block;
  width: 100%;
  padding: 10px 20px;
  font-size: 18px;
  line-height: 1.42857143;
  color: #444;
  background-color: #EAE9E9;
  background-image: none;
  border: none;
  border-radius: 25px;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
  min-height: 40px;
}
#contact .form-control:focus {
  border-color: #999;
  outline: 0;
  -webkit-box-shadow: transparent;
  box-shadow: transparent;
}
.form-control::-webkit-input-placeholder {
  color: #777;
}
.form-control:-moz-placeholder {
  color: #777;
}
.form-control::-moz-placeholder {
  color: #777;
}
.form-control:-ms-input-placeholder {
  color: #777;
}
#contact .contact-item {
  margin: 20px 0;
}
#contact .contact-item span {
  color: rgba(255, 255, 255, 1);
  margin-bottom: 10px;
  display: block;
}
#contact .contact-item i.fa {
  margin-right: 10px;
}
#contact .social {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  padding-top: 50px;
  margin-top: 50px;
  text-align: center;
}
#contact .social ul li {
  display: inline-block;
  margin: 0 20px;
}
#contact .social i.fa {
  font-size: 22px;
  width: 48px;
  height: 48px;
  padding: 12px 0;
  border: 2px solid #fff;
  color: #fff;
  border-radius: 50%;
  transition: all 0.3s;
}
#contact .social i.fa:hover {
  color: #608dfd;
  background: #fff;
}
#contact label {
  font-size: 20px;
}

@media (min-width: 768px) {
  #contact {
    padding: 100px 0 60px;
  }
  #contact h2 {
    font-size: 50px;
  }
  #contact .mt-30 {
    margin-top: 30px;
  }
  #contact .section-title {
    margin-bottom: 40px;
  }
}

/* Footer Section*/
#footer {
  background: #F2EAEA;
  padding: 20px 0;
}
#footer p {
  font-size: 15px;
}
#footer a:not(.footer-download-link) {
  color: #0000EE;
  font-size: 15px;
  margin-bottom: 5px;
}
#footer a:not(.footer-download-link):hover {
  border-bottom: 1px solid #0000EE;
}
#footer .footer-brand {
  font-size: 32px;
  font-weight: 700;
  color: #000;
}
.footer-download-icon {
  max-width: 160px;
}
@media (min-width: 768px) {
  .footer-download-links {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 20px;
  }
  .footer-download-icons {
    display: flex;
    gap: 10px;
  }
  #footer p {
    margin: 0;
  }
}


#privacy {
  padding: 20px 0;
}

#privacy h2 {
  font-size: 30px;
}

#privacy ul {
  font-size: 18px;
}

@media (min-width: 768px) {
  #privacy {
    padding: 50px 0;
  }

  #privacy h1 {
    font-size: 50px;
  }

}

div.PhoneInput {
  position: relative;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-space {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.gathrup-logo {
  height: 80px;
  width: 80px;
}

.logo-link {
  padding: 0;
  margin-left: 10px;
}

.navbar-links {
  display: none;
}

.navbar-logo-and-burger {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.burger-icon {
  padding: 0px 5px 4px 4px;
  border: 1px solid black;
  border-radius: 3px;
}

.burger-icon:hover {
  border: 1px solid blue;
  cursor: pointer;
}
